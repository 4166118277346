import styled from "@emotion/styled";
import { Typography } from "@ibeckinc/ui-elements";
import React from "react";

import { ContentLayout } from "../components/Layout";
import { pagesPath } from "../lib/$path";

const { Text } = Typography;

interface Props {
  content?: string;
}

const Template: React.FC<Props> = ({ content }) => {
  return (
    <ContentLayout
      breadcrumbs={[{ item: "404", path: pagesPath.$404.$url().pathname }]}
    >
      <Root>
        <_Text404 bold="bold" color="blueBlack">
          404
        </_Text404>
        <_Text color="blueBlack">
          {content ? content : "お探しのページは見つかりませんでした"}
        </_Text>
      </Root>
    </ContentLayout>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 80px;
  width: 100%;
`;

const _Text404 = styled(Text)`
  font-size: 80px;
  line-height: 80px;
  margin: 18px 0;
  padding: 10px;
  text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
`;

const _Text = styled(Text)`
  font-size: 18px;
  padding: 6px 10px 15px 10px;
  text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
`;

export default Template;
