import React from "react";

import { getPageMetadata, GetPageMetadataKey } from "../components/Head/query";
import Template from "../templates/404";
import { PageMetadata } from "../types/metadata";

export default function NotFound() {
  return <Template />;
}

export async function getStaticProps() {
  let metadata: PageMetadata | null = null;
  try {
    metadata = await getPageMetadata({ path: "/404" });
  } catch {
    metadata = null;
  }

  return {
    props: {
      fallback: {
        [GetPageMetadataKey("/")]: metadata,
      },
    },
  };
}
